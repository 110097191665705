import React, { useEffect, useState } from "react";
import { InfoCircle } from "iconsax-react";

const ClaimSuccess = ({
  handleModal,
  visible,
  handelLogout,
  type = "error",
}) => {
  const getBackgroundColor = () => {
    switch (type) {
      case "success":
        return "bg-green-500";
      case "warning":
        return "bg-yellow-500";
      case "error":
        return "bg-red-600";
      default:
        return "bg-teal-500";
    }
  };

  return (
    <div
      className={`fixed inset-0 ${
        visible ? "visible" : "invisible"
      } transform transition-transform duration-300`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all scale-0 ${
              visible ? "scale-100" : ""
            } sm:my-8 sm:w-full sm:max-w-sm`}
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="m-auto text-center">
                <div
                  className={`mx-auto h-16 m-auto w-16 flex-shrink-0 items-center justify-center p-3 rounded-full ${getBackgroundColor()}`}
                >
                  <InfoCircle size="40" className="text-primary_green-deep" />
                </div>
                <div className="mt-3 text-center">
                  <h3
                    className={`leading-10 text-gray-900 tennis-primary-font-bold text-2xl`}
                    id="modal-title"
                  >
                    Success
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-800 tennis-secondary-font">
                      You request is captured successfully.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                name="Logout"
                onClick={() => {
                  handelLogout();
                }}
                className={` inline-flex w-full rounded-md justify-center rounded-xs px-4 py-2 text-sm font-semibold bg-primary_green-deep text-white shadow-sm sm:ml-3 sm:w-auto`}
              >
                Ok
              </button>
              {visible && (
                <button
                  type="button"
                  name="Cancel"
                  onClick={() => {
                    handleModal(false);
                  }}
                  className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  sm:w-auto"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimSuccess;
