import React from 'react';

const LoadingButton = (props) => {
  return (
    <button className={`relative tennis-secondary-font flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md focus:outline-none focus:shadow-outline-blue active:bg-gray-50 transition duration-150 ease-in-out ${props.className}`}>
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V1.79A10 10 0 002.39 12H4zm2 8a8 8 0 016.21-7.8V4.01A10 10 0 004.01 15H6z"
          ></path>
        </svg>
      
      {props.name}
    </button>
  );
};

export default LoadingButton;
