import React, { useEffect, useMemo, useState } from 'react'
import logo from "../assets/images/logo.png";
import { HambergerMenu } from 'iconsax-react';

const menuItems = [
    { href: "#home", text: "Home" },
    { href: "#about", text: "About Us" },
    { href: "#services", text: "Services" },
    { href: "#register", text: "Register Your Claim" },
    { href: "#contact", text: "Contact" },
    { href: "#faqs", text: "FAQs" },
  ];
  

const Navbar = ({hideDropdown = false}) => {
    const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useMemo(() => {
    setIsOpen(hideDropdown?.open)
  }, [hideDropdown]);
    
  return (
    <header className='bg-white text-black'>
        <div className="flex items-center justify-between px-5 ">
          <div className="flex flex-row items-center">
            <div className="logo">
              <img src={logo} alt="Claim My Settlement Logo" />
            </div>
            <div className="logo-text ml-3">Claim My Settlement</div>
          </div>
          <nav>
            <ul className="hidden lg:block">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <a className='text-black hover:text-primary_green-light' href={item.href}>{item.text}</a>
                </li>
              ))}
            </ul>
            <div className="relative lg:hidden">
              <button onClick={toggleDropdown} className="px-4 py-2 rounded-md">
                <HambergerMenu
                  size="40"
                  className="text-black"
                  variant="Bulk"
                />
              </button>
              {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
                  {menuItems.map((item, index) => (
                    <a
                      href={item?.href}
                      onClick={toggleDropdown}
                      className="block px-4 py-2 text-primary_green-deep hover:bg-gray-200 "
                    >
                      {item?.text}
                    </a>
                  ))}
                </div>
              )}
            </div>
          </nav>
        </div>
      </header>
  )
}

export default Navbar