// src/components/Form.jsx

import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import ClaimSuccess from "./Modal/ClaimSuccess";
import TermsAndConditions from "./TermsAndConditions";
import SignaturePad from './InputFields/SignaturePad';
import { CLAIMSTATUS, states, referralList } from "../Constants/constantData";
import UploadDocument from './InputFields/UploadDocument';  // Import the new component
import { generatePrimaryKey, getCount } from "../api/fetchData";
import { storeData } from "../api/storeData";
import moment from 'moment-timezone';
import { uploadFile } from "../api/fileUpload";
import { dataURLtoFile, extractPlainNumber, phoneNumberFormatter } from "../Utility/fileUtilityFunction";
import Timer from './Timer';
import TermsText from "./TermsText";

moment.tz.setDefault('America/New_York');
const initialTermsState = {
  status: false,
  termError: false,
  signatureData: null,
  signatureError: false
}

const Form = () => {
  const [claimOpen, setClaimOpen] = useState(false);
  const [termsAccepts, setTermsAccepts] = useState(initialTermsState);
  const [isUSAddress, setIsUSAddress] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const closeToggle = () => {
    setClaimOpen(!claimOpen);
    reset();
  };

  const acceptHandler = (decision) => {
    if (decision) {
      setTermsAccepts({ ...termsAccepts, status: decision, termError: false });
    } else {
      setTermsAccepts({ ...termsAccepts, status: decision });
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    let hasError = false;
    let validationState = {...termsAccepts};
    if (!termsAccepts.status) {
      validationState = { ...validationState, termError: true };
      hasError = true;
    } else {
      formData.terms = true;
    }
    if (termsAccepts.signatureData == null) {
      validationState = { ...validationState, signatureError: true };
      hasError = true;
    }
    setTermsAccepts({...validationState});
    if (hasError) {
      alert('Form contains error! Please review and resubmit');
      setLoading(false)
      return;
    }
    
    await generatePrimaryKey().then((resItem) => {
      formData.id = resItem
    })
    formData.created_at = moment().format("YYYY-MM-DD_HH:mm:ss");
    formData.phone = extractPlainNumber(formData.phone)
    
    setTermsAccepts({ ...termsAccepts, termError: false });

    const signatureFile = dataURLtoFile(termsAccepts.signatureData, `${formData.full_name}_signature.png`);

    await uploadFile(formData.business_license[0]).then((resItem) => {
      formData.business_license = resItem
    })

    await uploadFile(formData.w9_form[0]).then((resItem) => {
      formData.w9_form = resItem
    })

    await uploadFile(formData.ein_tin_document[0]).then((resItem) => {
      formData.ein_tin_document = resItem
    })
    
    // Upload the signature
    await uploadFile(signatureFile).then((resItem) => {
      formData.signature = resItem
    })

    formData.status = CLAIMSTATUS.pending;
    
    await storeData({...formData}).then(() => {
      setClaimOpen(true);
      reset();
      setTermsAccepts(initialTermsState);
      console.log("Data stored successfully");
    }).catch(errors => {
      console.log("Form error : ",errors);
    }
    ).finally(() => setLoading(false))
  };

  const state = watch("state");

  useEffect(() => {
    setIsUSAddress(states.includes(state));
  }, [state]);

  const handleSignature = (data) => {
    if (data != null) {
      setTermsAccepts({...termsAccepts, signatureData: data, signatureError: false});
    } else {
      setTermsAccepts({...termsAccepts, signatureData: data, signatureError: true});
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white p-8 rounded-lg shadow-md container"
      >
        <h2 className="text-2xl font-bold mb-6 text-left">
          <strong style={{ color: "navy" }}>Reclaim What's Rightfully Yours !!!</strong>
        </h2>
        <Timer />
        <p className="my-3 text-sm text-gray-600">
          Initiate the journey towards reclaiming what you're owed. Complete our Registration Form tailored to your 
          headquarters or primary business location. For portfolios encompassing multiple independently operating 
          businesses, we advise separate registrations for each entity to ensure thorough recovery.
          Seize the chance to enrich your financial story. Allow Sagemont Services to be the dependable partner 
          leading you to the compensation you're entitled to.
          <br />
          <br />
          <b>Updated August 8, 2024: </b>On August 8, 2024, the Court granted a requested 180-day <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdfhttps://www.paymentcardsettlement.com/Content/Documents/SettlementUpdates/9380%20-%20Letter%20from%20Rule%2023b3%20Class%20Counsel%20requesting%20extension%20of%20claims-filing%20deadline%20with%20Declaration.pdf" className="text-blue-500 underline">Read Here </a>
          extension of the claims-filing deadline and extended the deadline to <b>Tuesday, February 4, 2025</b>
          <br />
          <br />
          Please fill out the fields below to help us assess your eligibility for compensation. For assistance, email at claimmysettlement@gmail.com :
        </p>

        <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
          <FormInput
            label="First Name"
            name="first_name"
            type="text"
            placeholder="Enter your First name"
            register={register}
            validation={{ required: "First Name required" }}
            error={errors?.first_name}
          />
          <FormInput
            label="Last Name"
            name="last_name"
            type="text"
            placeholder="Enter your Last name"
            register={register}
            validation={{ required: "Last Name required" }}
            error={errors?.last_name}
          />
          <FormInput
            label="Title"
            name="title"
            type="text"
            placeholder="Enter Title"
            register={register}
            validation={{ required: "Title required" }}
            error={errors?.title}
          />
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-4 mb-4">
        <FormInput
            label="Legal Business Name"
            name="business_name"
            type="text"
            placeholder="Enter your Business"
            register={register}
            validation={{ required: "Business name required" }}
            error={errors?.business_name}
          />
          <FormInput
            label="Estimated Annual Credit Sales"
            name="annual_credit_sales"
            type="text"
            placeholder="Enter Estimated Annual Credit Sales"
            register={register}
            requiredIndicator={false}
            error={errors?.annual_credit_sales}
          />
          <FormInput
            label="Client's Tax Identification No. (TIN)"
            name="clientEIN"
            type="text"
            placeholder="Enter your TIN/EIN in XX-XXXXXXX format"
            register={register}
            validation={{ required: "TIN required" }}
            error={errors?.clientEIN}
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 mb-4">
        <FormInput
            label="Doing Business as (D/B/A)"
            name="clientDBA"
            type="text"
            placeholder="Doing Business as .."
            register={register}
            requiredIndicator={false}
          />
          <FormInput
            label="Address"
            name="address"
            type="text"
            placeholder="Enter Address"
            register={register}
            validation={{ required: "Address required" }}
            error={errors?.address}
          />
        </div>

        <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
          <FormInput
            label="City"
            name="city"
            type="text"
            placeholder="Enter City"
            register={register}
            validation={{ required: "City required" }}
            error={errors?.city}
          />
          <div className="lg:w-1/2 relative">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="state"
            >
              State<span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <select
                name="state"
                id="state"
                {...register("state", { required: "State required" })}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
              >
                <option value="">Select a state</option>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {errors?.state && (
                <label className="text-red-400 text-sm">
                  {errors?.state?.message}
                </label>
              )}
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
          <FormInput
            label="Zip"
            name="zip"
            type="text"
            placeholder="Enter Zip"
            register={register}
            validation={{ required: "Zip required" }}
            error={errors?.zip}
          />
          <div className="lg:w-1/2 sm:mb-8 relative">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Do you have Multiple Locations ?
            </label>
            <select
              name="multiple_locations"
              {...register("multiple_locations", { required: "This field is required" })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
            >
              <option value="">Select an option</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </select>
            {errors?.multiple_locations && (
              <label className="text-red-400 text-sm">
                {errors?.multiple_locations?.message}
              </label>
            )}
          </div>
          <FormInput
            label="Email Address"
            name="email"
            type="email"
            placeholder="Enter your email"
            register={register}
            validation={{ required: "Email required" }}
            error={errors?.email}
          />
        </div>

        <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
          <div className="mb-4 lg:w-1/2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor={'phone'}
            >
              {'Phone Number'}
              {true && <span className="text-red-500">*</span>}
            </label>
            <Controller
              name={'phone'}
              control={control}
              rules={{ required: "Phone required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <div>
                  <input
                    type={'text'}
                    name={'phone'}
                    id={'phone'}
                    placeholder={'Phone Number'}
                    onBlur={onBlur}
                    onChange={(e) => {
                      const formattedValue = phoneNumberFormatter(e.target.value);
                      onChange(formattedValue);
                    }}
                    value={value}
                    ref={ref}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.phone && <label className="text-red-400 text-sm">{errors.phone.message}</label>}
                </div>
              )}
            />
          </div>
          <div className="lg:w-1/2 sm:mb-8 relative">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Referral Code
            </label>
            <select
              name="referral_code"
              {...register("referral_code")}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
            >
              <option value="">Select the Referral Code</option>
              {referralList.map((referral_code) => (
                <option key={referral_code} value={referral_code}>
                  {referral_code}
                </option>
              ))}
            </select>
          </div>      
        </div>
        <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
          <UploadDocument 
              name="business_license"
              label={"Business License"}
              register={register} 
              //validation={{ required: "Business License upload required" }}
              error={errors?.document} 
              showAsterisk={false}
          />
          <UploadDocument 
              name="ein_tin_document" 
              label={"EIN/TIN Document"}
              register={register} 
              //validation={{ required: "EIN/TIN Document upload required" }}
              error={errors?.document}
              showAsterisk={false} 
            />  
          
        </div>
        <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4">
            <UploadDocument 
              name="w9_form" 
              label={"W9 Form"}
              w9={true}
              register={register} 
              //validation={{ required: "W9 Document upload required" }}
              error={errors?.document} 
              showAsterisk={false}
            />    
        </div>

        <div className="mb-4 flex flex-col lg:flex-row lg:space-x-4 z-30">
          <div className="mb-4">
            <div className="flex flex-col items-start">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  checked={termsAccepts.status}
                  onChange={(e) => {acceptHandler(e.target.checked)}}
                  className="form-checkbox h-4 w-4 text-gray-600"
                />
                <label
                  className="text-gray-700 text-sm font-bold flex flex-row justify-center items-start"
                  htmlFor="terms"
                >
                  <span className="text-red-500 mr-2">*</span> I agree with the <span className="text-red-400 ml-1">Terms and Conditions below.</span>
                </label>
              </div>
                <TermsText />
          </div>
            {termsAccepts.termError && (
              <label className="text-red-400 text-sm">
                Please accept the Terms and Conditions
              </label>
            )}
            {!termsAccepts.termError && termsAccepts?.status && (
              <p className="text-primary_green-light mt-2">
                You have accepted the Terms and Conditions.
              </p>
            )}
          </div>
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-bold mb-2">Required Documents: Only those who had or having business between 2004 till 2019 (2020 onwards DO NOT waste time)</h3>
          <ul className="list-disc pl-5 text-gray-700">          
            <li>Merchant authorization agreement with Claim My Settlement as a third party.</li>
            <li>Merchants legal entity name and D/B/A if they have any.</li>
            <li>Merchant's business location address.</li>
            <li>Merchant legal entity’s Tax Identification Number (TIN).</li>
            <li>Merchant’s article of organization, Business License, Utility Bill, Credit card statement or Credit card year end 1099 form.</li>
            <li>Merchant's W-9 form.</li>
            <li>Merchant’s I’d number with the credit card processing company if they have it! (Not required)</li>
            <li>Authorized Legal owner's name, title, phone number, email address and mailing address.</li>
          </ul>
        </div>
        
        <SignaturePad signatureData={handleSignature} />
        {termsAccepts.signatureError && (
          <label className="text-red-400 text-sm">
            Please sign the form
          </label>
        )}

        <div className="flex items-center justify-end">
          <button
            type="submit"
            className={` ${loading ? "bg-gray-400 hover:bg-gray-600" : "bg-primary_green-deep hover:bg-primary_green-light"} text-white font-bold py-2 px-12 rounded-full focus:outline-none focus:shadow-outline`}
            disabled={loading}
          >
            {loading ? "Processing..." : 'Submit'}
          </button>
        </div>
      </form>

      <ClaimSuccess
        visible={claimOpen}
        handleModal={closeToggle}
        type="success"
        handelLogout={() => {
          closeToggle();
        }}
      />
    </>
  );
};

const FormInput = ({
  label,
  name,
  type,
  placeholder,
  register,
  validation,
  error,
  requiredIndicator = true
}) => (
  <div className="mb-4 lg:w-1/2">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor={name}
    >
      {label}
      {requiredIndicator && <span className="text-red-500">*</span>}
    </label>
    <div>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        {...register(name, validation)}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
      {error && <label className="text-red-400 text-sm">{error.message}</label>}
    </div>
  </div>
);

export default Form;
