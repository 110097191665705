
export const AWS_REGION = process.env.REACT_APP_AWS_REGION
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

export const AWS_S3_BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME
export const AWS_MEDIA_URL = process.env.REACT_APP_AWS_S3_URL

export const AWS_DYNAMO_DB_CLAIMS = process.env.REACT_APP_AWS_DYNAMO_DB_TABLE_NAME
export const AWS_DYNAMO_DB_ADMIN = process.env.REACT_APP_AWS_DYNAMO_DB_TABLE_ADMIN

export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY
export const ENCRYPTION_IV = process.env.REACT_APP_ENCRYPTION_IV