import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingButton from '../../Components/LoadingButton';
import { setLoginToken, setStatus } from '../../Redux/Slices/userSlice';
import { encrypt, validatePassword } from '../../api/adminLogin';
moment.tz.setDefault('America/New_York');

const Login = () => {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();

    const onSubmit = async (data) => {
      setIsLoading(true)
      try {
        await validatePassword(data.email, data.password).then((res) => {
          const isValid = res;
          if (isValid) {
            const accessToken = encrypt(moment(new Date()).add(5, 'days').format('YYYY-MM-DD_HH:mm:ss'));
            dispatch(setLoginToken(accessToken));
            dispatch(setStatus(true))
            toast.success("Login Successful");
            navigate('/')
          } else {
            toast.success("Invalid Credentials");
          }
        })
      } catch (error) {
        console.log('Error validating password:', error);
      } finally {
        setIsLoading(false);
      }

    }
  return (
    <div className="h-screen flex items-center justify-center w-screen font-roboto bg-gradient-to-r from-primary_green-deep to-primary_green-light">
        <div className="lg:w-1/3 md:w-1/2 sm:w-full bg-white m-auto shadow-2xl px-8 py-16 justify-between bg-opacity-45 rounded-md">
          <h1 className=" lg:text-2xl sm:text-lg border-l-4 border-primary_green-light py-6 bg-primary_green-deep text-white">
            <span className="flex flex-1 items-start justify-center mx-3 text-left"> Welcome to Claim My Settlement Admin Panel</span>
          </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                      className='mt-3'
                placeholder="Email Id"
                type="email"
                {...register('email', {required: "Email is required", minLength: 5 })}
                  />
                  {errors.email && <label className="text-red-600 text-sm font-semibold mb-3">{errors.email.message}</label>}
              <input
                placeholder="Password"
                type="password"
                {...register('password', {required: "Password is required", minLength: 5 })}
                  />
                {errors.password && <label className="text-red-600 text-sm font-semibold">{errors.password.message}</label>}
              {isLoading ? <LoadingButton className="text-center mt-4 py-3 w-full bg-gradient-to-r from-primary_green-deep to-gray-400 hover:opacity-80 text-white" name="Authenticating"></LoadingButton> : (
                <button type="submit" className="mt-4 w-full py-3 bg-primary_green-deep hover:from-teal-300 hover:to-teal-500 text-white tennis-primary-font-bold text-md">Sign In</button>
              )}
            </form>
        </div>
      </div>
  )
}

export default Login