import * as React from "react";

function Heart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 512.000000 512.000000"
      className="text-primary_green-light"
      {...props}
    >
      <path
        d="M1170 4635c-307-50-563-185-778-409-231-242-365-549-388-891-12-181 25-357 120-576 6-13-1-18-31-24-94-17-122-118-49-177 16-14 41-18 107-18h87l47-76c309-495 952-1163 1747-1816 190-156 222-178 256-178 35 0 54 13 212 140 347 279 764 654 774 696 16 63-34 124-102 124-31 0-65-26-237-184-163-150-487-425-632-539-15-11-28-2-242 176-537 448-1027 931-1337 1317-96 119-244 322-244 334 0 3 207 6 461 6 312 0 467 3 481 11 29 16 46 63 134 371 43 153 82 278 85 278s108-330 234-732c125-403 237-757 247-786 28-75 83-101 148-67 35 18 35 16 191 518l123 397h241c212 0 242 2 261 18 52 41 55 109 8 153l-26 24h-600l-26-30c-21-23-50-104-122-338-52-169-97-307-100-307s-113 345-244 768c-131 422-245 780-253 795-35 69-137 68-172-1-10-21-71-225-136-454l-119-418H353l-37 80c-98 215-128 369-107 558 52 474 361 865 799 1012 408 137 834 38 1167-270 67-63 79-70 115-70s48 7 115 70c190 175 390 276 625 315 493 80 989-178 1219-633 32-64 101-257 101-284 0-12-20-15-112-15-397-1-726-251-845-640-23-78-26-104-26-233 0-116 4-160 21-221 82-302 297-525 602-625 79-26 97-28 250-28s171 2 250 28c305 100 520 323 602 625 17 59 21 107 22 216 0 169-18 247-96 403-80 160-227 306-393 390l-60 30-18 93c-49 255-170 488-357 682-204 213-438 342-730 404-118 25-382 25-500 0-233-49-425-140-602-284l-68-55-67 55c-175 141-355 228-578 278-118 27-356 34-475 14zm3230-1347c25-6 87-30 139-55 77-37 108-59 176-126 91-92 131-155 170-271 104-311-42-664-337-812-405-204-883 30-974 477-31 153-2 326 78 469 70 124 235 255 383 305 102 34 250 39 365 13z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
      <path
        d="M4184 3072c-39-31-44-56-44-201v-141h-135c-187 0-227-19-227-108 0-28 7-43 31-64 31-28 31-28 180-28h150l3-155c3-153 3-155 30-182 39-38 97-38 136 0 27 27 27 29 30 182l3 155h150c149 0 149 0 180 28 24 21 31 36 31 64 0 89-40 108-227 108h-135v141c0 84-5 149-11 162-18 35-51 57-89 57-19 0-45-8-56-18zM3433 1703c-34-6-73-59-73-99 0-49 44-96 92-96 44 0 82 24 98 62 30 74-35 148-117 133z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
    </svg>
  );
}

export default Heart;
