import React, { useState } from "react";

const ExpandableTextSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left flex justify-between items-center py-2 px-4 focus:outline-none"
      >
        <span className={`text-lg font-medium ${isOpen ? "text-primary_green-deep" : ""}`}>{title}</span>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      <div
        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default ExpandableTextSection;