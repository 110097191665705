import React, { useState } from "react";
import DashBoardNavbar from "../../Components/DashBoardNavbar";
import Sidebar from "../../Components/Sidebar";
import { Menus } from "../../Constants/constantData";

const Dashboard = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [submenuOpen, setSubmenuOpen] = useState(
    Array(Menus.length).fill(true)
  );

  const handleSubmenuClick = (index, e) => {
    e.stopPropagation();
    const updatedSubmenuOpen = [...submenuOpen];
    updatedSubmenuOpen[index] = !updatedSubmenuOpen[index];
    setSubmenuOpen(updatedSubmenuOpen);
  };

  return (
    <div className="flex justify-center items-center">
      <DashBoardNavbar open={open} setOpen={setOpen} />
      <Sidebar
        open={open}
        setOpen={setOpen}
        submenuOpen={submenuOpen}
        handleSubmenuClick={handleSubmenuClick}
      />
      <div className={`w-full duration-300 mt-16 ${!open ? "pl-12" : "pl-52"}`}>
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default Dashboard;
