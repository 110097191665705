import { CloseCircle } from "iconsax-react";
import React from "react";
import { getStatusColors } from "../../Constants/constantData";
import { AWS_MEDIA_URL } from "../../Constants/deploymentConfig";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { phoneNumberFormatter } from "../../Utility/fileUtilityFunction";
import moment from 'moment-timezone';
moment.tz.setDefault("America/New_York");

const ClaimViewModal = ({ showBookingModal, data, onCloseView }) => {
  const { text: textColor, bg: bgColor } = getStatusColors(data?.status);
  const handleButtonClick = (documentUrl) => {
    if (!documentUrl) return;

    const fileExtension = documentUrl.split('.').pop().toLowerCase();
    const fullUrl = `${AWS_MEDIA_URL}${documentUrl}`;
  
    /**
     * We are only allowing image, PDf and Word formatted files from the form.
     */

    if (!(fileExtension === 'docx' || fileExtension === 'doc')) {
      // Open PDF and images in a new tab
      window.open(fullUrl, "_blank");
    } else if (fileExtension === 'docx' || fileExtension === 'doc') {
      // Prompt the user for download confirmation
      if (window.confirm("Browser does not support this format. Do you want to download this file?")) {
        // For DOCX and DOC files, we can use a link that prompts download
        const link = document.createElement('a');
        link.href = fullUrl;
        link.download = documentUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const openDocument = () => {
    const url = '/agreement-view';
    localStorage.setItem('documentData', JSON.stringify(data));
    setTimeout(() => {
      window.open(url, '_blank', 'noopener,noreferrer');
    },500)
  }

  return (
    <div
      className={`fixed z-10 inset-0 ${
        showBookingModal ? "visible" : "invisible"
      } transform transition-transform duration-300`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex justify-center items-center min-h-full">
          <div
            className={`relative transform overflow-hidden bg-white transition-all lg:w-6/12 sm:w-8/12 rounded-xl ${
              showBookingModal ? "scale-100" : "scale-0"
            }`}
          >
            <div className="modal-header p-4  text-gray-900 flex flex-row justify-between">
              <h5 className="text-primary_green-deep text-xl uppercase font-bold">
                Claim Request Details
              </h5>
              <span onClick={onCloseView}>
                <CloseCircle
                  size="32"
                  variant="Bold"
                  className="text-gray-500"
                />
              </span>
            </div>

            <div className="flex flex-row pl-4 items-center justify-between">
              <div className="flex-row">
                <span>Status : </span>{" "}
                <span
                  className={`ml-4 px-4 py-1 ${bgColor} ${textColor} rounded-full`}
                >
                  {data?.status !== undefined ? data?.status : "NA"}
                </span>
              </div>
              <button
                className="bg-primary_green-deep text-white px-3 py-1 mr-5 rounded-2xl"
                onClick={openDocument}
              >
                Download Consignment Form
              </button>
            </div>
            <div className="p-5 pl-4 pt-2">
              <table className="w-full">
                <tr className="">
                  <td className="w-1/3 border-b-2">
                    <p className="text-lg font-bold">Business Info</p>
                  </td>
                  <td className="w-2/3">
                    <p></p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Business Name
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.business_name ? data?.business_name : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Claim Submitted On
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.created_at ? moment(data?.created_at,'YYYY-MM-DD_HH:mm:ss').format('DD-MMM-YYYY hh:mm A') : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Title
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.title ? data?.title : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      First Name
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.first_name ? data?.first_name : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Last Name
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.last_name ? data?.last_name : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Annual Credit Sales
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>
                      {data?.annual_credit_sales
                        ? `$ ${Number(data?.annual_credit_sales).toFixed(2)}`
                        : "NA"}
                    </p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Tax Identification No. (EIN)
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.clientEIN ? data?.clientEIN : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Doing Business as (D/B/A)
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.clientDBA ? data?.clientDBA : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Multiple Location
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>
                      {data?.multiple_locations
                        ? data?.multiple_locations
                        : "NA"}
                    </p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Referral Code
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.referral ? data?.referral : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p></p>
                  </td>
                  <td className="w-2/3">
                    <p></p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3 border-b-2">
                    <p className="text-lg font-bold">Contact Info</p>
                  </td>
                  <td className="w-2/3 capitalize">
                    <p></p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Address
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.address ? data?.address : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      State
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.state ? data?.state : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Zip
                    </p>
                  </td>
                  <td className="w-2/3 capitalize text-sm text-gray-500">
                    <p>{data?.zip ? data?.zip : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Email
                    </p>
                  </td>
                  <td className="w-2/3 text-sm text-gray-500">
                    <p>{data?.email ? data?.email : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Phone
                    </p>
                  </td>
                  <td className="w-2/3 text-sm text-gray-500">
                    <p>{data?.phone ? phoneNumberFormatter(data?.phone) : "NA"}</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3">
                    <p></p>
                  </td>
                  <td className="w-2/3">
                    <p></p>
                  </td>
                </tr>
                <tr className="">
                  <td className="w-1/3 border-b-2">
                    <p className="text-lg font-bold">Documents Info</p>
                  </td>
                  <td className="w-2/3 capitalize">
                    <p></p>
                  </td>
                </tr>
                <tr className="">
                    <p className="font-semibold capitalize text-sm text-gray-500 pt-5">
                      Uploaded Document
                  </p>
                  </tr>
                {data?.business_license && data?.w9_form && data?.ein_tin_document ? (
                  <>
                    <tr className="">
                      <td className="py-4">{ `${data?.business_name} Business License`}</td>
                      <td className="w-2/3 text-sm py-4">
                        <button
                          type="button"
                          className="bg-primary_green-deep text-white px-3 py-1 rounded-2xl"
                          onClick={() => handleButtonClick(data?.business_license)}
                        >
                          View Document
                        </button>
                      </td>
                    </tr>
                    <tr className="pb-4">
                      <td>{ `${data?.business_name} W9 Form`}</td>
                      <td className="w-2/3 text-sm pb-4">
                        <button
                          type="button"
                          className="bg-primary_green-deep text-white px-3 py-1 rounded-2xl"
                          onClick={() => handleButtonClick(data?.w9_form)}
                        >
                          View Document
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="pb-4">{ `${data?.business_name} EIN/TIN Document`}</td>
                      <td className="w-2/3 text-sm pb-4">
                        <button
                          type="button"
                          className="bg-primary_green-deep text-white px-3 py-1 rounded-2xl"
                          onClick={() => handleButtonClick(data?.ein_tin_document)}
                        >
                          View Document
                        </button>
                      </td>
                    </tr>
                  </>
                  
                  ) : (
                      <tr>
                        <td className="w-2/3 text-sm">
                          <p>Document not available</p>
                        </td>
                      </tr>
                )}
                <tr className="h-5"></tr>
                <tr className="justify-center">
                  <td className="w-1/3">
                    <p className="font-semibold capitalize text-sm text-gray-500">
                      Signature
                    </p>
                  </td>
                  {data?.signature ? (
                    <td className="w-2/3">
                      <img
                        src={`${AWS_MEDIA_URL}${data?.signature}`}
                        alt="Signature"
                      />
                    </td>
                  ) : (
                    <td className="w-2/3 capitalize">
                      <p>Signature not available</p>
                    </td>
                  )}
                </tr>
              </table>
              <div className="flex justify-end space-x-3">
                {data?.document_urls?.map((documentUrl, index) => (
                  <button
                    key={index}
                    className="bg-primary_green-deep text-white px-3 py-1 rounded-2xl"
                    onClick={() => handleButtonClick(documentUrl)}
                  >
                    {documentUrl}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimViewModal;
