import React from 'react'
import ClaimTable from '../../Components/ClaimTable/ClaimTable'

const ClaimScreen = () => {
  return (
      <div>
          <h3 className='text-2xl font-semibold mb-5'>Claim Request List</h3>
          <ClaimTable />
    </div>
  )
}

export default ClaimScreen