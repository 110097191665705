import React, { useState } from "react";
import { AWS_DYNAMO_DB_CLAIMS } from "../../Constants/deploymentConfig";
import useClaimPagination from "../../api/useClaimPagination";
import Loader from "../Loader/Loader";
import { ArrowLeft, ArrowRight, Eye } from "iconsax-react";
import Table from "../Table/Table";
import CustomDataTable from "../Table/CustomDataTable";
import moment from "moment-timezone";
import ClaimViewModal from "../Modal/ClaimViewModal";
import { CLAIMSTATUS, getStatusColors } from "../../Constants/constantData";
import { updateClaimData } from "../../api/storeData";
import { phoneNumberFormatter } from "../../Utility/fileUtilityFunction";
moment.tz.setDefault("America/New_York");

const initialState = {
  showModal: false,
  data: null,
};
const ClaimTable = () => {
  const [claimViewData, setClaimViewData] = useState(initialState);
  const {
    items,
    loading: isLoading,
    error,
    loadNext,
    loadPrevious,
    isEndReached,
    isStart,
    resetPagination,
  } = useClaimPagination(10);

  const updateStatus = async (data) => {
    const key = { id: data?.id }; 
    const updatedAttributes = { status: data?.status }; 
    await updateClaimData(key, updatedAttributes);
    await resetPagination();
  }

  /*================================     column    ========================= */
  const date = (row) => (
    <h6 className="capitalize">
      {moment(row?.created_at, "YYYY-MM-DD_hh:mm:ss").format("MM/DD/YYYY")}
    </h6>
  );
  const time = (row) => (
    <h6 className="capitalize">
      {moment(row?.created_at, "YYYY-MM-DD_hh:mm:ss").format("hh:mm A")}
    </h6>
  );
  const full_name = (row) => <h6 className="capitalize">{`${row?.first_name?row?.first_name:"--"} ${row?.last_name?row?.last_name:"--"}`}</h6>;
  const business_name = (row) => (
    <h6 className="capitalize">
      {row?.business_name == "null" ? "NA" : row?.business_name}
    </h6>
  );
  const referral = (row) => <h6>{row?.referral ? row?.referral : "--"}</h6>;
  const phone = (row) => <h6>{row?.phone == "null" ? "NA" : phoneNumberFormatter(row?.phone)}</h6>;
  const status = (row) => {
    const { text: textColor, bg: bgColor } = getStatusColors(row?.status)
    return <select
      className={`capitalize px-3 py-1 ${textColor} ${bgColor} w-fit rounded-full`}
      value={row?.status === "null" ? "NA" : row?.status}
      onChange={(e) => updateStatus({ ...row, status: e.target.value })}
    >
      <option value="NA">NA</option>
      {Object.keys(CLAIMSTATUS).map((key) => (
        <option key={key} value={CLAIMSTATUS[key]}>
          {CLAIMSTATUS[key]}
        </option>
      ))}
    </select>
  };
  const action = (row) => (
    <div className="flex flex-row items-center">
      <Eye
        size="25"
        className="text-primary_green-light hover:text-primary_green-deep"
        onClick={() => setClaimViewData({ showModal: true, data: row })}
      />
    </div>
  );

  const columns = [
    { field: "created_at", header: "Date", body: date },
    { field: "created_at", header: "Time", body: time },
    { field: "full_name", header: "Full Name", body: full_name },
    { field: "business_name", header: "Business Name", body: business_name },
    { field: "phone", header: "Contact", body: phone },
    { field: "status", header: "Status", body: status },
    { field: "referral", header: "Referral", body: referral },
    { field: "Action", header: "Action", body: action },
  ];

  const onCloseView = () => {
    setClaimViewData(initialState);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {claimViewData.showModal && (
            <ClaimViewModal
              showBookingModal={claimViewData?.showModal}
              data={claimViewData?.data}
              onCloseView={onCloseView}
            />
          )}
          <CustomDataTable
            columns={columns}
            data={items}
            //hideAction={false}
            //onEditClick={onEditClick}
            //onDeleteClick={onDeleteClick}
          />
          <div className="flex flex-row justify-end gap-4 mt-3">
            <span
              onClick={() => {
                if (!isLoading || !isStart) {
                  loadPrevious();
                }
              }}
            >
              <ArrowLeft
                size="32"
                className={`${
                  isLoading || isStart
                    ? "text-gray-500"
                    : "text-primary_green-light hover:text-primary_green-deep"
                }`}
                variant="Bold"
              />
            </span>
            <span
              onClick={() => {
                if (!isLoading || !isEndReached) {
                  loadNext();
                }
              }}
            >
              <ArrowRight
                size="32"
                className={`${
                  isLoading || isEndReached
                    ? "text-gray-500"
                    : "text-primary_green-light hover:text-primary_green-deep"
                }`}
                variant="Bold"
              />
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default ClaimTable;
