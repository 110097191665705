import { Diagram, Element, User } from "iconsax-react";

export const Menus = [
  {
    title: "Dashboard",
    icon: <Element size="18" variant="Bold" />,
    link: "/",
  },
  {
    title: "Claim",
    icon: <Diagram size="18" variant="Broken"/>,
    link: "/claim-requests",
  },
  {
    title: "Contact Us",
    icon: <User size="18" variant="Bold" />,
    link: "/contact-requests",
  },
  //{
  //  title: "Users",
  //  icon: <User size="18" variant="Bold" />,
  //  submenu: true,
  //  submenuItems: [
  //    {
  //      title: "Users List",
  //      link: "/dashboard",
  //    },
  //  ]
  //},
];

export const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

export const referralList = [
    "CALL2024",
    "CALLIE2024",
  ];

export const CLAIMSTATUS = {
  pending : 'PENDING',
  approved : 'APPROVED',
  rejected : 'REJECTED',
  inProgress : 'IN_PROGRESS',
  completed : 'COMPLETED',
}

const statusColors = {
  PENDING: { text: 'text-blue-500', bg: 'bg-blue-100' },
  APPROVED: { text: 'text-green-500', bg: 'bg-green-100' },
  REJECTED: { text: 'text-red-500', bg: 'bg-red-100' },
  IN_PROGRESS: { text: 'text-orange-500', bg: 'bg-orange-100' },
  COMPLETED: { text: 'text-gray-500', bg: 'bg-gray-100' },
};

export const getStatusColors = (status) => {
  return statusColors[status] || { text: 'black', bg: 'gray-100' };
};