import React, { useState } from "react";
import handshakeImage from "../assets/images/Handshake.jpg"; // Placeholder image
import technology from "../assets/images/iStock-1160576467.jpg";
import strategy from "../assets/images/strategicplanning.jpg";

const tabs = [
  { name: "White Glove Service" },
  { name: "Technological Excellence" },
  { name: "Strategic Insights" },
];

const About = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
    <div className="container font-roboto flex flex-col items-center h-auto transition-all duration-500">
      <div className="w-full p-4">
        <p className="text-start text-3xl font-bold mb-3">About</p>
        <p className="text-start text-lg font-medium mb-3">The Claim My Settlement Experience</p>

        <div className="flex flex-1 flex-col mx-auto mt-10">
          <div className="flex justify-around border-b-2 border-gray-200 md:mx-10 lg:mx-24">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`flex-1 py-2 px-4 text-center transition-all duration-300 ${
                  activeTab === index
                    ? "border-b-2 border-primary_green-light text-primary_green-light"
                    : "border-b-2 border-transparent text-primary_green-deep"
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.name}
              </button>
            ))}
          </div>

          {activeTab === 0 && (
            <div className="p-4 pt-7 animate-fade-in">
              <p className="text-center text-primary_green-deep text-lg font-semibold">White Glove Service</p>
              <p className="text-center text-gray-400 text-base">
                At the heart of our practice lies a symphony of exceptional client support, pioneering research, and supreme data
                management. These pillars of excellence are the foundation of our bespoke platform renowned for its comprehensive coverage
                providing a seamless single point of contact for every stakeholder in our client's journey.
              </p>
              <div className="mt-8 flex flex-col lg:flex-row items-center">
                <div className="w-full lg:w-2/5 lg:mr-5">
                  {!videoLoaded && (
                    <img src={handshakeImage} alt="Handshake" className="rounded-md w-full h-auto object-cover" loading="lazy" />
                  )}
                  <video
                    className={`rounded-md w-full h-auto object-cover ${videoLoaded ? '' : 'hidden'}`}
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="auto"
                    poster={handshakeImage}
                    onLoadedData={() => setVideoLoaded(true)}
                  >
                    <source src={"https://s3-media-bucket-cms.s3.us-east-2.amazonaws.com/videos/Services1.mp4"} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="w-full lg:w-3/5 p-4 mt-5 lg:mt-0 text-lg leading-relaxed">
                  <p>
                    At Claim My Settlement, we envision more than just providing services or consulting in the intricate 
                    field of class action settlements. We aim to be your strategic partner, exceeding the usual 
                    expectations of a settlement recovery consultant. We combine empathy, expertise, commitment, 
                    and care to ensure every dollar owed is returned to your business. Our extensive experience 
                    isn't merely a feature—it's the cornerstone of your success. Since our incorporation in 
                    Knoxville in 2021, we are committed to achieve remarkable outcomes. Our team is helping hundreds 
                    of clients recover the thousands of dollars they are owed, all on a contingency basis. You only 
                    pay us if we successfully recover funds for your business.
                  </p>
                </div>
              </div>
            </div>
          )}

          {activeTab === 1 && (
            <div className="p-4 pt-7 animate-fade-in">
              <p className="text-center text-primary_green-deep text-lg font-semibold">Technological Excellence</p>
              <p className="text-center text-gray-400 text-base">
                At Claim My Settlement, we are committed to staying at the forefront of technological innovation. Our team continuously
                integrates the latest advancements to enhance our service offerings, ensuring that we deliver cutting-edge solutions tailored
                to meet the unique needs of our clients. From leveraging AI-driven analytics to deploying robust cybersecurity measures, we
                ensure that every project we undertake is executed with precision, efficiency, and reliability. Our dedication to
                technological excellence empowers us to streamline operations, drive growth, and provide unparalleled value to our customers.
              </p>
              <div className="mt-8 flex flex-col lg:flex-row items-center">
                <img src={technology} alt="Technology" className="rounded-md w-full lg:w-2/5 lg:mr-5 h-auto object-cover" loading="lazy" />
                <div className="w-full lg:w-3/5 p-4 mt-5 lg:mt-0 text-lg leading-relaxed">
                  <p>
                    Our relentless pursuit of technological excellence is evident in every aspect of our operations. We invest heavily in
                    research and development to identify and implement the most effective tools and methodologies. This proactive approach
                    enables us to anticipate industry trends and respond swiftly to the evolving demands of the market. By fostering a culture
                    of continuous improvement and innovation, Claim My Settlement not only meets but exceeds the expectations of our clients,
                    providing them with a competitive edge in an increasingly digital world.
                  </p>
                </div>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="p-4 pt-7 animate-fade-in">
              <p className="text-center text-primary_green-deep text-lg font-semibold">Strategic Insights</p>
              <p className="text-center text-gray-400 text-base">
                Our strategic insights are derived from a deep understanding of the market dynamics and client needs. We offer tailored
                solutions that are designed to drive growth, enhance operational efficiency, and improve overall performance. Our team of
                experts works closely with clients to develop strategies that are both innovative and actionable, ensuring that they achieve
                their business objectives.
              </p>
              <div className="mt-8 flex flex-col lg:flex-row items-center">
                <img src={strategy} alt="Strategy" className="rounded-md w-full lg:w-2/5 lg:mr-5 h-auto object-cover" loading="lazy" />
                <div className="w-full lg:w-3/5 p-4 mt-5 lg:mt-0 text-lg leading-relaxed">
                  <p>
                    We leverage our extensive industry experience and deep understanding of market trends to provide our clients with
                    strategic insights that drive success. Our approach is collaborative, working closely with clients to understand their
                    unique challenges and opportunities. This enables us to develop and implement strategies that are not only effective but
                    also sustainable in the long term. By focusing on innovation, efficiency, and client satisfaction, we help businesses
                    achieve their goals and thrive in a competitive landscape.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
