import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
//core
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";

function CustomDataTable({ data, columns, pagination=false }) {
    return (
        <DataTable
            value={data}
            emptyMessage="Data not found!"
            tableStyle={{ minWidth: '50rem' }}
            stripedRows
            //resizableColumns="expand"
            paginator={pagination}
            rows={10}
            //rowsPerPageOptions={[25, 50, 100]}
            //sortMode="multiple"
        >
            {columns.map((col) => (
                <Column key={col.field} field={col.field} header={col.header} body={col.body} style={col.style ? "" : { width: '' }} sortable={!col.sortable} />
            ))}
        </DataTable>
    )
}

export default CustomDataTable