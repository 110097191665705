import React from 'react';
import contactImage from '../assets/images/contact.jpg';
import { CallCalling, SmsTracking, Location } from 'iconsax-react';

const ContactUs = () => {
  const backgroundStyle = {
    backgroundImage: `url(${contactImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  };

  return (
    <div style={backgroundStyle}>
      <h1 className="text-4xl font-bold mb-6 text-white">Contact Us</h1>
      <div className="flex flex-col md:flex-row w-full max-w-5xl p-4 md:p-8 rounded-xl shadow-lg text-gray-600 bg-white bg-opacity-50">
        <div className="md:w-1/2 p-4 md:p-8 text-gray-800">
          <p className="mb-6">
            Feel free to contact our team at Claim My Settlement. We are here to support you every step of the way and address any additional questions or concerns you may have regarding the Visa/Mastercard Interchange Fee Settlement.
          </p>
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <CallCalling size="24" className="text-primary_green-light" variant="TwoTone" />
              <span>(865) 300-7125</span>
            </div>
            <div className="flex items-center space-x-2">
              <SmsTracking size="24" className="text-primary_green-light" variant="Outline" />
              <span>claimmysettlement@gmail.com</span>
            </div>
            <div className="flex items-center space-x-2">
              <Location size="24" className="text-primary_green-light" variant="Bold" />
              <span>P.O. BOX 22670, Knoxville, TN, 37933</span>
            </div>
          </div>
          <div className="mt-6">
            <iframe
	    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25861.874277354593!2d-84.18896883803437!3d35.88002798042627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885c2eddc7a4f5ad%3A0x256b14e569e3130!2sKnoxville%2C%20TN%2037933!5e0!3m2!1sen!2sus!4v1719446558511!5m2!1sen!2sus"
              width="100%"
              height="320"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className="md:w-1/2 p-4 md:p-8">
          <form className="flex flex-col space-y-4">
            <div>
              <label htmlFor="name" className="text-sm">Your Name</label>
              <input type="text" id="name" placeholder="Your name" className="ring-1 mt-2 ring-gray-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-teal-300" />
            </div>
            <div>
              <label htmlFor="email" className="text-sm">Your Email Address</label>
              <input type="email" id="email" placeholder="Your Email Address" className="ring-1 mt-2 ring-gray-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-teal-300" />
            </div>
            <div>
              <label htmlFor="subject" className="text-sm">Email Subject</label>
              <input type="text" id="subject" placeholder="Email subject" className="ring-1 mt-2 ring-gray-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-teal-300" />
            </div>
            <div>
              <label htmlFor="phone" className="text-sm">Your Phone No</label>
              <input type="text" id="phone" placeholder="Phone no" className="ring-1 mt-2 ring-gray-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-teal-300" />
            </div>
            <div>
              <label htmlFor="message" className="text-sm">How Can We Help You</label>
              <textarea id="message" rows="4" placeholder="Message" className="ring-1 mt-2 ring-gray-300 w-full rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-teal-300"></textarea>
            </div>
            <button type="submit" className="self-end bg-cyan-700 text-white font-bold rounded-lg px-6 py-2 uppercase text-sm">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
