import * as React from "react";

function TestTube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="75"
      viewBox="0 0 512.000000 512.000000"
      className="text-primary_green-light"
      {...props}
    >
      <path
        d="M4464 5107c-84-33-138-93-160-178-31-117 36-245 151-289 44-17 135-15 180 5 22 9 55 30 73 48 158 152 66 410-151 423-35 2-76-2-93-9zm136-179c13-14 20-35 20-59 0-71-85-105-135-54-31 30-32 73-4 109 27 34 88 37 119 4zM3439 4842c-179-94-179-343 0-434 122-62 271-15 332 104 108 211-122 440-332 330zm150-147c31-15 41-33 41-70s-10-55-42-71c-86-45-161 68-87 130 35 30 49 32 88 11zM2692 4442c-66-24-118-65-159-126-39-58-53-103-53-177 0-75 21-132 77-202l46-59-1205-1206C619 1891 180 1445 156 1408c-54-84-103-192-128-283-31-114-31-334 1-450 86-317 329-560 646-646 115-32 335-32 450 0 91 24 199 73 283 127 37 24 484 464 1263 1242l1207 1204 65-50c76-58 139-77 231-70 108 9 204 76 253 176 38 77 40 192 4 267-35 73-1422 1464-1497 1501-71 35-171 41-242 16zm165-163c27-10 1330-1302 1395-1383 43-53 50-129 18-182-31-49-96-78-152-69-35 6-58 23-140 103l-99 96-432-432-432-432H945l147 147 148 148 93-93 93-92 59 60 59 60-92 93-92 92 65 65 65 65 175-175 175-175 57 58 58 57-175 175-175 175 65 65 65 65 92-92 93-92 60 59 60 59-92 93-93 93 65 65 65 65 175-175 175-175 58 57 57 58-175 175-175 175 65 65 65 65 93-93 93-92 59 60 59 60-92 93-92 92 65 65 65 65 175-175 175-175 57 58 58 57-175 175-175 175 65 65 65 65 420-420c231-231 425-420 430-420 6 0 35 25 65 55l55 55-522 523c-488 488-522 525-528 564-9 56 21 125 67 153 36 22 100 26 145 9zm-767-3222c-745-743-767-765-847-806-114-58-211-81-342-81-133 0-210 18-331 77-80 39-108 59-185 137-151 151-215 305-215 516 0 132 23 229 82 343 38 74 64 105 287 330l245 247h2071l-765-763z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
      <path
        d="M746 1556c-59-22-108-67-139-127-25-46-28-63-25-120 5-88 42-151 116-195 63-38 115-48 182-33 177 38 251 261 132 399-19 23-52 50-75 61-52 27-140 34-191 15zm131-171c41-29 38-98-6-132-39-30-112 0-125 52-7 27 15 80 36 88 26 11 74 7 95-8zM1670 1561c-74-24-139-82-166-147-22-52-18-141 10-199 58-126 208-176 333-112 182 93 176 347-10 443-35 19-131 27-167 15zm105-167c24-10 46-60 39-89-13-52-86-82-125-52-44 34-47 103-6 132 22 16 64 20 92 9zM937 819c-56-13-90-33-130-78-119-131-60-341 112-399 161-55 341 94 317 265-19 143-163 245-299 212zm108-184c53-52 18-135-56-135-71 0-105 85-54 135 15 16 36 25 55 25s40-9 55-25zM4673 4189c-70-27-146-97-180-167-24-49-28-69-28-142s4-93 28-142c64-130 218-209 352-181 78 17 123 41 175 93 70 69 94 128 94 230 1 74-3 93-27 142-75 153-258 227-414 167zm202-168c95-55 102-209 12-273-42-30-123-36-172-13-98 47-119 194-37 268 52 47 134 54 197 18z"
        transform="matrix(.1 0 0 -.1 0 512)"
        fill="currentColor"
      />
    </svg>
  );
}

export default TestTube;
