import CryptoJS from "crypto-js";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import dynamoDb from "./aws-config";

const {
  AWS_DYNAMO_DB_ADMIN,
  ENCRYPTION_KEY,
  ENCRYPTION_IV,
} = require("../Constants/deploymentConfig");

const tableName = AWS_DYNAMO_DB_ADMIN;

const algorithm = "aes-256-cbc"; // AES encryption
const key = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY); // Must be 256 bits (32 bytes)
const iv = CryptoJS.enc.Hex.parse(ENCRYPTION_IV); // Must be 16 bytes

export const encrypt = (text) => {
  let encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
  return encrypted.toString();
};

const decrypt = (cipherText) => {
  let decrypted = CryptoJS.AES.decrypt(cipherText, key, { iv: iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

const encryptObjectFields = (obj, fieldsToEncrypt) => {
  const encryptedObj = { ...obj };
  fieldsToEncrypt.forEach((field) => {
    if (encryptedObj[field]) {
      encryptedObj[field] = encrypt(encryptedObj[field]);
    }
  });
  return encryptedObj;
};

export const addUser = async (user) => {
  const fieldsToEncrypt = ["password"];
  if (!user?.email) {
    throw new Error("Email is required");
  }
  user.id = user.email;

  const uuid = uuidv4();
  const now = new Date();
  const formattedTimeStamp = moment(now).format("YYYY-MM-DD_HH-mm-ss");
  const primaryKey = `${uuid}-${formattedTimeStamp}`;
  user.uniqueId = primaryKey;

  const encryptedUser = encryptObjectFields(user, fieldsToEncrypt);

  const params = {
    TableName: tableName,
    Item: encryptedUser,
  };

  try {
    await dynamoDb.put(params).promise();
    return `Admin added successfully ${user.email}`;
  } catch (err) {
    console.error("Error adding user:", err);
    throw new Error("Error adding user");
  }
};

export const validatePassword = async (email, password) => {
  const params = {
    TableName: tableName,
    Key: {
      id: email,
    },
  };

  try {
    const result = await dynamoDb.get(params).promise();
    const user = result.Item;

    if (!user) {
      console.log("User not found:", email);
      return false;
    }

    const decryptedPassword = decrypt(user.password);

    return decryptedPassword === password;
  } catch (err) {
    console.error("Error validating password:", err);
    return false;
  }
};

export const isValidAccessToken = (encryptedToken) => {
  try {
      const decryptedDate = decrypt(encryptedToken);
      const tokenDate = moment(decryptedDate, 'YYYY-MM-DD_HH:mm:ss');
      const currentDate = moment(new Date());
      if (currentDate.isBefore(tokenDate)) {
          return true;
      } else {
          return false;
      }
  } catch (e) {
      console.error('Error decrypting the token:', e);
      return false;
  }
}
