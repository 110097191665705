import { AWS_DYNAMO_DB_CLAIMS } from "../Constants/deploymentConfig";
import dynamoDb from "./aws-config";

export const storeData = async (item) => {
  const params = {
    TableName: AWS_DYNAMO_DB_CLAIMS,
    Item: item
  };

  try {
    await dynamoDb.put(params).promise();
    return "Data stored successfully";
  } catch (error) {
    console.error("Error storing data:", error);
    throw new Error("Error storing data");
  }
};

export const updateClaimData = async (key, updatedAttributes) => {
  const params = {
    TableName: AWS_DYNAMO_DB_CLAIMS,
    Key: key,
    UpdateExpression: `set ${Object.keys(updatedAttributes).map((attr, index) => `#attr${index} = :val${index}`).join(', ')}`,
    ExpressionAttributeNames: Object.keys(updatedAttributes).reduce((acc, attr, index) => {
      acc[`#attr${index}`] = attr;
      return acc;
    }, {}),
    ExpressionAttributeValues: Object.keys(updatedAttributes).reduce((acc, attr, index) => {
      acc[`:val${index}`] = updatedAttributes[attr];
      return acc;
    }, {}),
    ReturnValues: "UPDATED_NEW",
  };

  try {
    const data = await dynamoDb.update(params).promise();
    return data.Attributes;
  } catch (error) {
    console.log("Error updating data:", error);
    throw new Error("Error updating data");
  }
};
