export const faqData = [
  {
    question: "What types of settlements do you handle?",
    answer: [
      "We handle a wide range of settlements, including class action settlements, consumer protection claims, and corporate misconduct cases.",
    ],
  },
  {
    question: "How do I know if my business is eligible for a claim?",
    answer: [
      "Our team will conduct a thorough review of your situation and the settlement criteria to determine your eligibility.",
    ],
  },
  {
    question: "What documents are required for the claims process?",
    answer: [
      "The required documents vary depending on the specific settlement, but generally include proof of purchase, transaction records, and relevant communications.",
    ],
  },
  {
    question: "How long does the settlement recovery process take?",
    answer: [
      "The timeline can vary widely depending on the complexity of the case and the settlement terms. Our team will keep you informed throughout the process.",
    ],
  },
  {
    question: "What fees do you charge for your services?",
    answer: [
      "Our fees are typically contingent on the successful recovery of your claim. We will discuss all terms and conditions upfront to ensure transparency.",
      "If you have any additional questions or need further clarification, please do not hesitate to contact us. We are here to support you every step of the way.",
    ],
  },
];

export const faqVisaData = [
  {
    question: "What is the Visa/Mastercard Interchange Fee Settlement?",
    answer: [
      "The Visa/Mastercard Interchange Fee Settlement addresses improper interchange fees charged to merchants who accepted Visa or Mastercard credit or debit cards between January 1, 2004, and January 25, 2019.",
    ],
  },
  {
    question: "Who is eligible to participate in the settlement?",
    answer: [
      "Merchants who accepted Visa or Mastercard credit or debit cards during the specified period and were harmed by the interchange fees may be eligible to participate in the settlement.",
    ],
  },
  {
    question: "How do I know if my business qualifies for a claim?",
    answer: [
      "Our team at Claim My Settlement will conduct a thorough review of your business's transactions and circumstances to determine eligibility for a claim.",
    ],
  },
  {
    question: "What allegations were made against Visa, Mastercard, and their member banks in this lawsuit?",
    answer: [
      "Merchants alleged that Visa, Mastercard, and their member banks violated the law by setting interchange fees and enforcing rules that limited merchants from steering customers to other payment methods. Allegations also included conspiracy claims regarding business practices.",
    ],
  },
  {
    question: "What is the process for filing a claim?",
    answer: [
      "Claim My Settlement will guide you through the process of gathering necessary documentation and preparing and filing your claim on your behalf.",
    ],
  },
  {
    question: "What types of compensation can eligible merchants expect to receive?",
    answer: [
      "The compensation will vary depending on factors such as the volume of transactions and the extent of harm suffered. Our team will work to ensure you receive the maximum compensation possible.",
    ],
  },
]
