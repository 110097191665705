import React, { useEffect, useState } from "react";
import "../index.css";
import About from "./About";
import ContactUs from "./ContactUs";
import FAQs from "./FAQs";
import Form from "./Form";
import Navbar from "./Navbar";
import OurServices from "./OurServices";
import { addUser } from "../api/adminLogin";

const Main = () => {
  const [hideDropdown, setHideDropdown] = useState({ open: false });

  useEffect(() => {
    /**
     * This function is used to fetch the data from dynamoDB
     */
    //fetchData().then((resItem) => {
    //  console.log("Data fetched successfully",resItem); https://mimaxxservice.com
    //});
    //const createAndTestAdminUser = async () => {
    //  await addUser({ first_name: 'admin', last_name: 'admin', email: 'admin@claimmysettlement.com', password: 'admin@claimmysettlement.com', role: 'super_admin' }).then(() => console.log('user added')).catch((err) => console.log(err));
    //  //await validatePassword("admin@claimmysettlement.com", "admin@claimmysettlement.com").then((res) => console.log(res)).catch((err) => console.log(err));
    //}
    //createAndTestAdminUser();
  }, []);
  //console.log("hideDropdown", hideDropdown);

  return (
    <>
      <Navbar hideDropdown={hideDropdown} />
      <div onClick={() => setHideDropdown({ open: false })}>
        <section
          id="home"
          style={{ padding: "0px" }}
          className="animate-on-scroll font-roboto mt-12"
        >
          <div className="hero relative h-screen min-h-[50vh]">
            <video
              className="absolute inset-0 w-full h-full object-cover opacity-50"
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              poster="../assets/images/poster.jpg" // Optional: Add a poster image for better UX during loading
            >
              <source src={"https://s3-media-bucket-cms.s3.us-east-2.amazonaws.com/videos/Introduction.mp4"} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-4">
              <p className="text-gray-900 font-dancing-script font-bold text-2xl md:text-3xl">
                Welcome to Claim My Settlement
              </p>
              <h2 className="text-gray-900 text-4xl md:text-5xl font-bold mb-10">
                Your trusted partner for business solutions
              </h2>
              <div className="flex flex-row space-x-5">
                <a
                  href="#services"
                  className="bg-primary_green-deep hover:bg-primary_green-light py-2 px-5 shadow-2xl shadow-black rounded-full text-white"
                >
                  Our Services
                </a>
                <br></br>
                <a
                  href="#about"
                  className="bg-primary_green-deep hover:bg-primary_green-light py-2 px-5 shadow-2xl shadow-black rounded-full text-white"
                >
                  About
                </a>
                <br></br>
                <a
                  href="#register"
                  className="bg-primary_green-deep hover:bg-primary_green-light py-2 px-5 shadow-2xl shadow-black rounded-full text-white"
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="animate-on-scroll">
          <About />
        </section>
        <section id="services" className="animate-on-scroll">
          <OurServices />
        </section>
        <section id="register" className="animate-on-scroll">
          <div className="container">
            <div className="font-roboto mx-2">
              <Form />
            </div>
          </div>
        </section>
        <section id="contact" className="animate-on-scroll">
          <ContactUs />
        </section>
        <section id="faqs" className="animate-on-scroll">
          <FAQs />
        </section>
      </div>
    </>
  );
};

export default Main;
